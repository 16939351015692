.root {
}

.inputError {
  border: var(--borderErrorField);
}

.helperText{
  font-size: 14px;
  color: gray;
  text-align: end;
}