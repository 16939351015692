/* SkeletonCard.css */
.skeleton-card {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 180px;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .skeleton-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .skeleton-title,
  .skeleton-subtitle,
  .skeleton-text,
  .skeleton-rating {
    height: 15px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeleton-title {
    width: 70%;
  }
  
  .skeleton-subtitle {
    width: 50%;
  }
  
  .skeleton-text {
    width: 90%;
  }
  
  .skeleton-rating {
    width: 30%;
  }
  