@import '../../styles//customMediaQueries.css';

.keywordSearchContainer {
  flex: 2;
  @media (max-width: 1023px) {
    position: relative;
  }
}

.federatedSearchDiv {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 8px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;

    @media (max-width: 460px) {
      height: 4px;
      width: 6px;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 6px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  & .imageSeacrhResult {
    display: flex;
    width: 100%;
    gap: 14px;
    align-items: center;
  }

  & > div {
    &:first-child {
      width: 35%;

      @media (max-width: 460px) {
        width: 100%;
      }
    }

    &:last-child {
      width: 65%;

      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }
}

.keywordSearchWrapper {
  position: relative;
  width: 100%;

  & .voiceSearch {
    position: absolute;
  }
}

.outSideClickContainer {
  width: 100%;
}

.keywordSearch {
  padding-left: 52px;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M17.0208%2015.0833H16.0004L15.6388%2014.7346C16.9483%2013.2156%2017.6681%2011.2764%2017.6667%209.27084C17.6667%207.6103%2017.1743%205.98705%2016.2517%204.60636C15.3292%203.22567%2014.0179%202.14956%2012.4838%201.5141C10.9496%200.878638%209.26152%200.712372%207.63289%201.03633C6.00426%201.36028%204.50826%202.15991%203.33409%203.33409C2.15991%204.50826%201.36028%206.00426%201.03633%207.63289C0.712372%209.26152%200.878638%2010.9496%201.5141%2012.4838C2.14956%2014.0179%203.22567%2015.3292%204.60636%2016.2517C5.98705%2017.1743%207.6103%2017.6667%209.27084%2017.6667C11.3504%2017.6667%2013.2621%2016.9046%2014.7346%2015.6388L15.0833%2016.0004V17.0208L21.5417%2023.4663L23.4663%2021.5417L17.0208%2015.0833ZM9.27084%2015.0833C6.05459%2015.0833%203.45834%2012.4871%203.45834%209.27084C3.45834%206.05459%206.05459%203.45834%209.27084%203.45834C12.4871%203.45834%2015.0833%206.05459%2015.0833%209.27084C15.0833%2012.4871%2012.4871%2015.0833%209.27084%2015.0833Z%22%20fill%3D%22%23E27757%22%2F%3E%3C%2Fsvg%3E');
  background-position: 16px 20px;
  width: 100%;
  height: 65px;
  top: 174px;
  left: 38px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 4px 4px 0px 0px #dfc6b8;

  /* opacity: 0px; */
  /* background: #FFFFFF; */
  border-radius: 8px;
  padding-right: 52px;

  @media (max-width: 767px) {
    padding-left: 50px;
    height: 48px;
    background-size: 20px;
    background-position: 15px 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.0208 15.0833H16.0004L15.6388 14.7346C16.9483 13.2156 17.6681 11.2764 17.6667 9.27084C17.6667 7.6103 17.1743 5.98705 16.2517 4.60636C15.3292 3.22567 14.0179 2.14956 12.4838 1.5141C10.9496 0.878638 9.26152 0.712372 7.63289 1.03633C6.00426 1.36028 4.50826 2.15991 3.33409 3.33409C2.15991 4.50826 1.36028 6.00426 1.03633 7.63289C0.712372 9.26152 0.878638 10.9496 1.5141 12.4838C2.14956 14.0179 3.22567 15.3292 4.60636 16.2517C5.98705 17.1743 7.6103 17.6667 9.27084 17.6667C11.3504 17.6667 13.2621 16.9046 14.7346 15.6388L15.0833 16.0004V17.0208L21.5417 23.4663L23.4663 21.5417L17.0208 15.0833ZM9.27084 15.0833C6.05459 15.0833 3.45834 12.4871 3.45834 9.27084C3.45834 6.05459 6.05459 3.45834 9.27084 3.45834C12.4871 3.45834 15.0833 6.05459 15.0833 9.27084C15.0833 12.4871 12.4871 15.0833 9.27084 15.0833Z' fill='%23E27757' /%3E%3C/svg%3E ");
  }

  &::placeholder {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    padding-right: 34px;
    color: #686363;
  }
}

.keywordSearch::placeholder{
  color: rgb(163, 161, 161);
}

.kewordSearchContainer {
  width: 100%;
  height: 500px;
  position: absolute;
  background: #fff;
  z-index: 2;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  /* @media (max-width: 2000px) {
      width: 700px;
    }
    @media (max-width: 1550px) {
      width: 600px;
    }
    @media (max-width: 1400px) {
      width: 500px;
    }
    @media (max-width: 1200px) {
      width: 430px;
    }
    @media (max-width: 1023px) {
      width: 100%;
    } */
}

.closed {
  display: none;
}

.collapseKeywordSearch {
  font-size: 22px;
  font-weight: 800;
  color: #333;
  border: none;
  cursor: pointer;
  position: sticky;
  line-height: 18px;
  display: block;
  margin-left: auto;
  @media(--viewportMedium){
    margin-top: 8px;
  }
  & svg {
    width: 14px;
    height: 14px;
    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
    }
  }
}

.searchHitsTitle {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 3px;
  padding: 0;
}

.recentSearches {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  @media (--viewportMedium) {
    box-shadow: none;
  }
}

.totalItem {
  /* border: 1px solid gray; */
  border-radius: 10px;
  padding: 0 5px 5px;
  margin: 0;
}

.recentSearchesHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.4s ease;

  & .arrowButton {
    border: none;
    & .arrowIcon {
      cursor: pointer;
    }
    & .active {
      transform: rotate(176deg);
    }
  }
}

.recentSearchItem {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 6px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(128, 128, 128, 0.386);
  line-height: 28px;
  &:last-child {
    border-bottom: none;
  }

  & > span {
    &:nth-child(2) {
      @media (max-width: 460px) {
        margin-right: 10px;
        margin-left: 4px;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .recentLabel {
    width: auto;
    overflow: hidden;
    text-align: center;
  }
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.searchHits {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 150px;
  position: relative;

  /* & .hitsContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
    } */
  & .searchRentalHit {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    position: relative;

    & > div {
      &:nth-child(1) {
        & > div {
          padding-bottom: 100% !important;
        }
      }

      & > div {
        & > div {
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }

          & svg {
            width: 54px;
            height: auto;
            object-fit: cover;
          }
        }
      }

      /* &:nth-child(2) {
        & > div > div {
          font-size: 12px;
          padding: 0px 12px;
          font-family: 'Poppins';
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.75px;
          text-align: left;
          color: #0e1319;
        }
      } */
    }
  }

  & .searchStoriesHit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    /* padding: 28px 0px; */
    white-space: pre-line;
    cursor: pointer;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    & .avtar {
      width: 120px;
      height: 120px;
      object-fit: cover;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 4px;
      /* background: lightgray 50% / cover no-repeat; */
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
        5px 5px 0px 0px #e3c0ac;
      /* margin-right: 1rem; */
    }

    & .noImageContainer {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 4px;
      background: #e27757;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
        5px 5px 0px 0px #e3c0ac;
      margin-right: 1rem; */
    }

    & .storeName {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 800;
      /* line-height: 42px; */
      letter-spacing: 0.06em;
      text-align: center;
      color: #2b3740;
      margin: 10px 0 0 0;
      padding: 0;
    }

    & .location {
      /* font-family: "Avenir"; */
      font-size: 14px;
      font-weight: 900;
      /* line-height: 14px; */
      text-align: center;
      color: #e27757;
      margin: 0;
      padding: 0;
    }

    & .ratingContent {
      display: flex;
      justify-content: center;
      /* margin-top: 8px; */
      gap: 2px;

      & svg {
        fill: #e0d687;
        width: 14px;
        height: 14px;
      }
    }
  }
}
.info {
  padding: 0 12px 8px;
  & .title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.75px;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 8px;
    margin-bottom: 8px;
    color: #0e1319;
  }
}
.price {
  & span {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #0e1319;
  }
}
.additional {
  position: absolute;
  z-index: 1;
  right: 0;
  padding: 0 5px;
  background: #e27757;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
}

.clearKeywords {
  border: none;
  position: absolute;
  top: 19px;
  right: 70px;
  cursor: pointer;
  &:hover {
    background-color: #f4e8e1;
  }
}

.morePrices {
  cursor: pointer;

  font-family: Inter;
  font-size: 10px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #000;
  text-transform: uppercase;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchTeamButton {
  width: 173px;
  min-height: 38px;
  margin-bottom: 10px;
  box-shadow: 3px 3px 0px 0px #dfc6b8;
  border-radius: 100px;
  margin: 0;
  &:hover{
    box-shadow: 4px 4px 0px 0px #dfc6b8;
  }
}
.getHelpContent {
  display: flex;
  gap: 12px;
  /* align-items: center; */
  justify-content: space-between;
  /* padding-right: 40px; */
  margin: 16px 0 0;
  @media (max-width: 1200px) {
    margin: 16px 0 16px;
    padding-right: 0;
  }
  & .lookingForText {
    margin: 0;
    padding: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.userRating {
  margin: 0 !important;
  & svg {
    width: 14px !important;
    height: 14px !important;
    fill: #e0d687 !important;
  }
}

.keywordSearchCard {
  width: auto !important;
  margin-bottom: 1rem;
}
