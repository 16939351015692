.btn {
  cursor: pointer;
  border: none;
}
.container {
  position: absolute;
  top: 12px;
  right: 10px;
  @media (max-width: 767px) {
    top: 10px;
    right: 4px;
  }
}

.speechButton {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 3em;
  color: #fff;
  padding: 0;
  margin: 0;
  background: #e27757;
  position: relative;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  & svg {
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
}
.speechMicBefore {
  & svg {
    width: 30px;
    height: 30px;
    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
    }
  }
}
.speechButtonBefore {
  background: transparent;
}
.pulseRing {
  content: '';
  width: 40px;
  height: 40px;
  background: #e27757;
  border: 5px solid #e27757;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: pulsate infinite 1.5s;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}

/* .pulse-ring.delay {
	// animation-delay: 1s;
} */

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

.listeningContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-left {
  margin-left: 100px * 3;
}

.type2 {
  background: #189bff;
}

.type2 .pulse-ring {
  background: transparent;
}