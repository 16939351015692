@import '../../styles/customMediaQueries.css';


.avatarContainer{
  position: relative;
}

.avatarBase {
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--colorWhite);

  &:hover {
    text-decoration: none;
  }
}

/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;
  position: relative;
  & img{
    object-fit: cover;
  }
}

.initials {
  composes: textXSmall from global;
  padding: 0;
  
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.smallAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 32px;
  height: 32px;
}
.initialsSmall {
  /* Exception and unique font-size for the small avatar */
  font-size: 11px;
  font-weight: var(--fontWeightBold);
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}
.initialsMedium {
  composes: textLarge from global;
  padding: 0;
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;
  padding: 0;
  position: relative;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.initialsLarge {
  composes: h3 from global;
  padding: 0;
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  z-index: 999;
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;

  font-size: 12px;
  
  /* Dimensions */
  width: 25px;
  height: 25px;
  /* padding: 11px 10px 7px 35px; */

  /* Look and feel (buttonish) */
  background-color: var(--colorWhite);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px var(--colorGrey100);
  border-radius: 50%;

  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  cursor: pointer;

  @media (--viewportMedium) {
    bottom: -13px;
    right: 6px;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    /* padding: 11px 10px 7px 35px; */
  }

  &:hover {
    border: solid 1px var(--colorGrey300);
  }
}

.linkAvatarWithoutImage{
  position: relative;
}

.changeAvatarLarge{
  /* Font */
  z-index: 999;
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: -22px;
  right: 24px;

  font-size: 0;
  
  /* Dimensions */
  width: 50px;
  height: 50px;
  /* padding: 11px 10px 7px 35px; */

  /* Look and feel (buttonish) */
  background-color: var(--colorWhite);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: center;
  border: solid 1px var(--colorGrey100);
  border-radius: 50%;

  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  cursor: pointer;

  @media (--viewportMedium) {
   bottom: -25px;
   right: 25px;
   margin-top: 0;
   margin-bottom: 0;
   transition: var(--transitionStyleButton);
   /* padding: 11px 10px 7px 35px; */
  }

  &:hover {
    border: solid 1px var(--colorGrey300);
  }
}

.name{
  color: #fff!important;
}