.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 6px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  transform: translate3d(0, 0, 0);
  border-radius: 20px 20px 0 0;
  transition: transform 0.3s ease-in-out;
  @media (min-width: 768px) {
    display: none;
  }
  &>div{
    height: 36px;
    width: 36px;
  }
}
.hidden {
  transform: translateY(100%);
}

.visible {
  transform: translateY(0);
}
.iconWrapper {
  height: 24px;
  margin-bottom: 2px;
  & svg {
    width: 20px;
  }
}
.messageIcon {
  & svg {
    width: 22px;
  }
}
.profileIcon {
  & svg {
    width: 19px;
  }
}
.navLink {
  font-family: Inter;
  font-size: 12px;
  font-weight: bolder;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #676a76;
}
.navbar :global(.NamedLink_active),
.active {
  .navLink {
    color: var(--marketplaceColor);
  }
  & svg {
    & path {
      fill: var(--marketplaceColor);
    }
  }
}
.navItem {
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    & svg {
      & path {
        fill: var(--marketplaceColor);
      }
    }
    & .navLink {
      color: var(--marketplaceColor);
    }
  }
  & svg{
    fill: #333;
  }
}
