@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;
  /* border-bottom: 1px solid #FBF7F4; */

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
}

.dayLabel {
  composes: textSmall from global;
  margin: 0;
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 6px;

}

.dayInfo {
  font-size: 14px;
}

.totalPrice {
  composes: p from global;
  margin: 0;
  padding: 0;
  /* font-family: Avenir; */
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.75px;
  text-align: right;
  @media (max-width: 767px) {
    font-size: 20px;
}
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3047058880329132px;
  text-align: left;
  padding: 0;
}
.itemLabelDate {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3047058880329132px;
  text-align: left;
  padding: 0;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3047058880329132px;
  text-align: right;
  
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: #FBF7F4;

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  composes: marketplaceSmallFontStyles from global;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3047058880329132px;
  text-align: left;
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 12px;
  }
}

/* PickupReturnOptions.module.css */
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* padding: 8px;
  border: 1px solid #ddd; */
  border-radius: 8px;
  /* background-color: #f9f9f9; */
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  & svg{
    fill: var(--marketplaceColor);
    width: 18px !important;
    height: 18px !important;
  }
}

.icon {
  width: 16px;
  height: 16px;
  fill: var(--marketplaceColor);
}
.pickupReturnModal{
  z-index: 999;
  &>div {
    &>div {
        padding: 0 !important;

        & :global(.mapboxgl-canvas) {
            width: 100% !important;
            object-fit: cover !important;

            @media(max-width:768px) {
                height: 100% !important;
            }
        }

        &>div {
          &>div {
            @media(max-width:768px) {
              min-height: 100vh;
              padding: 0;
            }
          }
        }
    }
  }
}

.map{
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}