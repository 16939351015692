.root {
    background: lightgray;
    padding: 8px 0;
    text-transform: capitalize;
}

.breadcrumbLinks {
    display: flex;
    gap: 10px;
    margin: 0;

    @media (max-width: 767px) {
        padding-left: 24px;
    }
}

.link::after {
    content: ' >';
}