@import '../../../styles/customMediaQueries.css';


.packagesContainer {
    display: flex;
    gap: 1rem;
    max-width:  100vw;
    padding: 8px 16px 16px;
    @media (--viewportMedium) {
        max-width: 450px;
        padding: 1rem;
      }
    overflow-x: scroll;
  
    & .package {
      padding: 10px;
      border-radius: 5px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
      min-width: 110px;
      height: 68px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        height: 60px;
      }
      &:hover {
        transform: translateY(-2px);
        transition: all 0.4s ease;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
        @media (max-width: 767px) {
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
          transform: translateY(-2px);
        }
      }
      & .packageLable {
        color: gray;
        margin: 0;
      }
      & .packagePrice {
        font-weight: 900;
        margin: 0;
        @media (max-width: 767px) {
          font-weight: 800;
          line-height: 20px;
        }
      }
    }
    & .active {
      background-color: #f2704e;
      color: #ffff;
      & .packageLable {
        color: #fff;
        padding: 0;
        margin: 0;
      }
      & .packagePrice {
        color: #fff;
        padding: 0;
        margin: 0;
      }
    }
  }
  