@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--marketplaceColor);
  text-align: center;
  position: relative;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--colorGrey700);
}

.noImageText {
  composes: h6 from global;
  color: var(--colorGrey700);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.noImage{
  box-sizing: content-box;
  stroke: var(--colorGrey700);
  color: #FFF;
  font-size: 52px;
}

.rootImgURL {
  display: block;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0;
  overflow: hidden;
  background-color: var(--colorGrey100); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.rootImgURLwithAlgin{
  display: block;
  position: relative;
  margin: 0;
  overflow: hidden;
  background-color: white;
  text-align: center;
}

.removeImage {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 33px;
  height: 33px;
  padding: 0;

  border: none;

  cursor: pointer;

}