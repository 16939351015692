.root{
position: relative;

}

.daysAndHours {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}

.field {
  margin-bottom: 1rem;
}

.minMax {
  display: flex;
  gap: 10px;
}

.imageTypes {
  font-size: 13px;
}

.formIntro {
  font-size: 14px;
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addImageInput {
  display: none;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.aspectRatio {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 1rem;
}

.topLabel {
  font-size: 14px;
}

.datefield {
  z-index: 9999;
  position: relative;

  &>div {
    &:nth-child(2) {

      &>div>div>div {
        gap: 10px;
      }
    }
  }
}
.bottomWrapper {
  margin-top: 30px;
  @media(max-width:768px) {
    position: fixed;
    bottom: 60px;
    right: 50%;
    z-index: calc(var(--zIndexModal) + 1);
    width: 100%;
    translate:50% 0;
    padding-inline: 1.5rem;
  }

}
.searchTeamModal {
  &>div>div {
    &>button {
      @media(max-width:768px) {
        top: 45px;
      }
    }
  }
}

.fieldsTitle{
  margin-bottom: 0;
  font-size: 1.2rem;
}
.question{
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;
}

.error{
  color: red;
}

.mainTitle{
  color: var(--marketplaceColor);
  @media(max-width:786px) {
    padding-top: 60px;
  }
}

.lastField{
  @media(max-width:786px) {
    padding-bottom: 250px;
  }
}

.image{
  width: 200px;
  height: 200px;
  position: relative;

  & .removeImage{
    position: absolute;
    border: none;
    right: 0px;
    cursor: pointer;
  }
}