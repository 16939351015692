.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    /* Full viewport height */
}

.loader {
    border: 6px solid #f3f3f3;
    /* Light grey */
    border-top: 6px solid var(--marketplaceColor);
    /* Red */
    border-radius: 50%;
    width: 90px;
    height: 90px;
    animation: spin 2s linear infinite;
}

.loaderContainer.small {
    height: 100%;

    & .loader {
        width: 50px;
        height: 50px;
    }
}


@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  