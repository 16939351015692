.ratingContent {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    gap: 1px;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    & svg {
      color: #e0d687;
      width: 20px;
      height: 20px;
    }
  }

  .starIconGrey {
    color: rgb(199, 199, 199);
    font-size: 1.5rem;
  }