@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 42px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 89px;
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 28px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
   }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
   }
}


.reviews{
  font-family: 'Inter';
  color: #383f52;
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  padding: 0;
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: flex;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  & span{
    &::first-letter{
      text-transform: capitalize;
    }
  }
  & .ratings{
     margin: 0;
     margin-left: 2px;
     display: flex;
     justify-content: center;
     align-items: center;
     & svg{
      width: 15px;
      height: 15px;
     }
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderFormContainer {
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px 16px 10px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  & .openOrderForm {
    /* Ensure that mobile button is over Footer too */
    display: flex;
    gap: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    & button {
      background-color: #2b3740;
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
  
      box-shadow: 3px 3px 0px 0px #e3c0ac;
      height: 36px;
      min-height: 36px !important;
      &::first-letter {
        text-transform: capitalize;
      }
      &:hover, &:focus {
        box-shadow: 3px 3px 0px 0px #e0d687 !important;
        color: #000;
        background-color: #fff !important;
      }
    }
  }
  & .openOrderFormMargin{
    @media (max-width: 1023px) {
      margin-bottom: 54px;
      & button{
        min-height: 48px;
      }
    }
  }
  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}



.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;
  margin: 8px 0;
 /* justify-content: center; */
  @media (--viewportMedium) {
    padding: 4px 0;
    /* justify-content: flex-start; */
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.promotionalText{
  color: #000;
  font-size: 14px;
  text-align: center;
  padding: 4px 20px 0;
  /* margin-inline: 10px; */
  margin: 0;
  /* font-weight: 600; */
}

.verifyShield{
  width: 18px!important;
  height: 18px!important;
  margin-left: 2px;
}



.avatarMediumMob{
  margin-right: 10px;

}

.authorMob{
  padding: 10px;
  background-color: #FBF7F4;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  display: flex;
  margin-inline: 10px;
  margin-top: 10px;
}

.providerNameLinkedMob {
  flex-direction: column;
  flex-grow: 1;

  .ratingsMob {
    margin-top: 5px !important;
    justify-content: flex-start !important;

    & svg {
      width: 15px !important;
      height: 15px !important;
    }
  }

}
.reviewsMob, .totalListings{
  font-family: 'Inter';
  color: #383f52;
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  padding: 0;
}
.contactButton{
   border: none;
}

.avatarMediumMob{
  margin-right: 10px;
  & img{
    object-fit: cover;
    min-width: 60px;
  }
}

.author{
  @media(--viewportMedium) {
      padding: 10px;
      background-color: #FBF7F4;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 20px;
      display: flex;
      margin-inline: 10px;
      margin-top: 16px;
      margin-bottom: 16px;
    }


  .contactButton{
    display: none;
    @media(--viewportMedium){
      display: block;
    }
  }

  .avatarMedium{
    display: none;
    @media(--viewportMedium){
      display: flex;
      margin-right: 10px;
    }
    & img{
      object-fit: cover;
      min-width: 60px;
    }
  }
  
  .providerNameLinked {
    display: none;

    @media(--viewportMedium){
      display: block;
      flex-direction: column;
      flex-grow: 1;
    }
    
    .ratings {
      margin-top: 5px !important;
      justify-content: flex-start !important;
  
      & svg {
        width: 15px !important;
        height: 15px !important;
      }
    }
  
    .reviews{
      font-family: 'Inter';
      color: #383f52;
      font-size: 14px;
      font-weight: 400;
      border: none;
      cursor: pointer;
      padding: 0;
    }
  }
}